import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {

  static values = {
    locale: String,
    key: String
  }

  connect() {
  }

  click(e) {
    e.preventDefault()
  }

  save() {
    this.element.classList.add('loading')
    axios.patch('/admin/translations', {
      translation: {
        locale: this.localeValue,
        key: this.keyValue,
        value: this.element.innerHTML
      }
    }).then(res => {
      this.element.classList.remove('loading')
    })

  }
}